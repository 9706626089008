import { Navigate, useLocation } from "react-router-dom";
import Document from "../components/document/routes";
import ChinesFormDetails from "../components/chineseFormDetails/routes";
import BasicDetails from "../components/basicdetail/routes";
import Vfs from "../components/vfsuk";
import Checkout from "../components/checkout/page";

const hostname = window.location.hostname;

export const publicRoutes =
hostname == "cnsh.commbitz.com"
// hostname == "localhost"
    ? [
        { path: "/", element: <ChinesFormDetails /> },
        { path: "*", element: <Navigate replace to="/" /> },
      ]
    : hostname == "cnsh.wp.commbitz.com"
    ? [
        { path: "/", element: <ChinesFormDetails /> },
        { path: "*", element: <Navigate replace to="/" /> },
      ]
    : // : hostname == "vfsphilippines.commbitz.com" ? [
      //   { path: "/", element: <Vfs /> },
      //   { path: "/checkout", element: <Checkout /> }
      // ]
      // : [
      //   { path: "/", element: <BasicDetails /> },
      //   { path: "*", element: <Navigate replace to="/" /> }
      // ];

      [
        { path: "/", element: <Vfs /> },
        { path: "/checkout/:id", element: <Checkout /> },
        { path: "*", element: <Navigate replace to="/" /> },
      ];

// export const publicRoutes = hostname == 'localhost' ? [
//   { path: "/", element: <ChinesFormDetails /> },
//   { path: "*", element: <Navigate replace to="/" /> }
// ] :
//   [{ path: "/", element: <BasicDetails /> },
//   { path: "*", element: <Navigate replace to="/" /> }
//   ];

// export const publicRoutes = hostname == 'localhost' ? [
//   { path: "/", element: <ChinesFormDetails /> },
//   { path: "*", element: <Navigate replace to="/" /> }
// ] :
//   [{ path: "/", element: <BasicDetails /> },
//   { path: "*", element: <Navigate replace to="/" /> }
//   ];
